<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <img
        v-if="currentLogo != ''"
        src="@/assets/images/logo/logo.png"
        alt="logo"
        style="width: 28px"
      />

      <span class="d-none d-sm-inline-block"
        >FAMAMETRIC, All rights Reserved</span
      >
    </span>
  </p>
</template>
<script>
import axios from '@axios';
import { blobToBase64ForLogo, readOnly } from '@/auth/utils';
import { $themeConfig } from '@themeConfig';

export default {
  data() {
    const { appLogoImage } = $themeConfig.app;

    return {
      appLogoImage,

      readOnly: true,
      currentLogo: '',
      imageSource: ''
    };
  },
  async mounted() {
    await this.getLogo();
    this.readOnly = readOnly();
  },
  methods: {
    showToast({ variant = 'success', title, text, icon = 'XIcon' }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },
    async getLogo() {
      let result = await axios.get('/company-logo');
      if (result.status == 200) {
        console.log(result.data);
        let image = blobToBase64ForLogo(result.data.image.data);
        this.currentLogo = image;
        let q = localStorage.getItem('userInfo');
        if (q) {
          let r = JSON.parse(q);
          if (r) {
            r.companyName = result.data.company_name;
            this.companyName = r.companyName;
            localStorage.setItem('userInfo', JSON.stringify(r));
          }
        }
      }
    }
  }
};
</script>