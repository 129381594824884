export default [
    {
        title: 'navbar.statistics',
        route: 'statistics',
        icon: 'HomeIcon',
        canSee  : true
    },{
        title: 'navbar.home',
        route: 'home',
        icon: 'HomeIcon',
        canSee  : true
    },
    {
        title: 'navbar.work',
        route: 'table-work',
        canSee  : true
    },
    {
        title: 'navbar.photos',
        route: 'photos',
        icon: 'ImageIcon',
        canSee  : true
    },
    {
        title: 'navbar.note',
        route: 'table-note',
        icon: 'InfoIcon',
        canSee  : true
    },
    {
        title: 'navbar.problems',
        route: 'table-general-problem',
        icon: 'AlertTriangleIcon',
        canSee  : true
    },
    {
        title: 'navbar.Meterials',
        route: 'table-meterials',
        icon: 'AlertTriangleIcon',
        canSee  : true

    },
    {
        title: 'navbar.reports',
        icon: 'FeatherIcon',
        canSee  : true,
        children: [

            {
                title: 'navbar.pivotTry',
                route: 'report-pivot-try',
                canSee  : true
            },{
                title: 'navbar.pivotPersonel',
                route: 'report-pivot-personel',
                canSee  : true
            },{
                title: 'navbar.pivotMachine',
                route: 'report-pivot-machine',
                canSee  : true
            },{
                title: 'navbar.pivotMaterial',
                route: 'report-pivot-material',
                canSee  : true
            },
            {
                title: 'navbar.pivot2',
                route: 'report-pivot2',
                canSee  : true
            },
            // {
            //     title: 'navbar.pivot3',
            //     route: 'report-pivot3',
            //     canSee  : true
            // },
        ]
    },
    {
        title: 'navbar.workApprovalStatus',
        route: 'table-work-approval-status',
        canSee  : true
    },
    {
        title: 'navbar.tables',
        icon: 'ListIcon',
        canSee  : true,
        children: [
            {
                title: 'navbar.boq',
                route: 'table-boq',
                canSee  : true
            },
            {
                title: 'navbar.subBoq',
                route: 'table-sub-boq',
                canSee  : true
            },
            {
                title: 'navbar.wbsSubBoq',
                route: 'table-wbs-sub-boq',
                canSee  : true
            },
            {
                title: 'navbar.wbs',
                route: 'table-wbs',
                canSee  : true
            },
            {
                title: 'navbar.activity',
                route: 'table-activity',
                canSee  : true
            },
            {
                title: 'navbar.material',
                route: 'table-material',
                canSee  : true
            },
            {
                title: 'navbar.unit',
                route: 'table-unit',
                canSee  : true
            },
            {
                title: 'navbar.subContractor',
                route: 'table-sub-contractor',
                canSee  : true
            },
            {
                title: 'navbar.subContractorUser',
                route: 'table-sub-contractor-user',
                canSee  : true
            },
            {
                title: 'navbar.user',
                route: 'table-user',
                canSee  : true
            },
            {
                title: 'navbar.organizationChart',
                route: 'table-organization-chart',
                canSee  : true
            },
            {
                title: 'navbar.role',
                route: 'table-role',
                canSee  : true
            },
            {
                title: 'navbar.rolePages',
                route: 'table-role-pages',
                canSee  : true
            },
            {
                title: 'navbar.companyPhoto',
                route: 'setting-companyPhoto',
                icon: 'HomeIcon',
                canSee  : true
            },
            {
                title: 'navbar.deleteData',
                route: 'deleteData',
                icon: 'HomeIcon',
                canSee  : true
            },
        ]
    },


];
