<template>
  <div
      class="main-menu menu-fixed menu-accordion menu-shadow"
      :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered)
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
      @mouseenter="updateMouseHovered(true)"
      @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
          name="header"
          :toggleVerticalMenuActive="toggleVerticalMenuActive"
          :toggleCollapsed="toggleCollapsed"
          :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo">
                <b-img :src="appLogoImage" alt="logo"/>
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                  icon="XIcon"
                  size="20"
                  class="d-block d-xl-none"
                  @click="toggleVerticalMenuActive"
              />
              <feather-icon
                  :icon="collapseTogglerIconFeather"
                  size="20"
                  class="d-none d-xl-block collapse-toggle-icon"
                  @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom"/>

    <!-- main menu content-->
    <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="main-menu-content scroll-area"
        tagname="ul"
        @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items
          :items="menuItemsFama"
          class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import {BLink, BImg} from 'bootstrap-vue';
import {provide, computed, ref} from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';
import {$themeConfig} from '@themeConfig';
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue';
import useVerticalNavMenu from './useVerticalNavMenu';
import axios from '@/libs/axios';
import {decryptJwt} from "@/auth/utils";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  data() {
    return {
      menuItemsFama: [],
    }
  },
  async mounted() {
    // await this.getPageAuths();
    let t = localStorage.getItem('token')
    let q = decryptJwt(t)
    navMenuItems.forEach(n => {
      if (n.children === undefined) {
        let check = q.find(a => a.title === n.title)
        if (check !== undefined) {
          this.menuItemsFama.push(n)
        }
      } else {
        let removeItems = []
        let altItems = [];
        for (let i = 0; i < n.children.length; i++) {
          let check = q.find(a => a.title === n.children[i].title)
          if (check !== undefined) {
            // check.canSee = true
            altItems.push(check)
          } else {
            removeItems.push(n.children[i].title)
            // n.children[i].canSee = false
          }
        }
        // removeItems.forEach(a => {
        //   n.children.splice(a, 1)
        // })
        removeItems.forEach(l => {
          n.children = n.children.filter(nn => nn.title !== l)
        })

        if (n.children.length > 0) {
          this.menuItemsFama.push(n)
        }
      }
    })
  },
  methods: {
    async getPageAuths() {
      const pageAuths = await axios.post('/get-page-all-auth');
    }
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered
    } = useVerticalNavMenu(props);

    const {skin} = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide('isMouseHovered', isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false
    };

    const collapseTogglerIconFeather = computed(() =>
        collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'
    );

    // App Name
    const {appName, appLogoImage} = $themeConfig.app;

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage
    };
  }
};
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
</style>
