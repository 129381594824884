<template>
  <DxSelectBox v-if="sites.length > 1"
      :search-enabled="true"
      :value="site"
      :items="sites"
      display-expr="name"
      value-expr="id"
      @value-changed="changeSites"

  />
</template>

<script>
import {DxSelectBox} from 'devextreme-vue/select-box';


export default {
  components: {
    DxSelectBox
  },
  data() {
    return {
      sites: [],
      site: {}
    }
  },
  methods: {
    changeSites(i) {
        console.log(i)
        if(i){
            localStorage.setItem('siteId',i.value)
            if(this.site !== i.value)
              location.reload()
        }
    },
    getFirms() {
      this.site = +(localStorage.getItem('siteId'))

      let q = localStorage.getItem('sites')
      if (q) {
        this.sites = (JSON.parse(q))
        console.log(this.sites)
      }
    }
  },
  mounted() {
    this.getFirms()
  }

};
</script>

<style></style>
